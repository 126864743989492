import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SubHero from '../components/sub-hero/sub-hero'
import IndustrySpecific from '../components/info-sections/industry-specifc'
import RiskBased from '../components/info-sections/risk-based'

const AboutPage = () => {
  return (
    <div>
      <Layout title="Services">
        <SubHero
          title="Our Services"
          subtitle={
            <>
              Max<sup key="">2</sup> Security is on a mission to 
              make the cyber world a safer place and to detangle 
              the technical from the basics of risk management in 
              a new domain.
            </>
          }
        />
        <IndustrySpecific />
        <RiskBased />
      </Layout>
    </div>
  )
}

export default AboutPage
