import React from 'react'
import {
  RiFolderUserLine,
  RiBodyScanLine,
  RiBarChart2Fill
} from "react-icons/ri"

import Container from '../container/container'
import styles from './section.module.scss'
import wrapperStyles from './wrapper.module.scss'

const RiskBased = () => {
  return (
    <div className={wrapperStyles.wrapper}>
      <Container>
        <div className={`${styles.section} ${styles.rightAlign}`}>
          <h3 className={styles.title}>
            Risk-Based
          </h3>
          <div className={styles.divider} />
          <div className={styles.text}>
            <p>
              Focusing on threats and vulnerabilities alone neglects the balance needed to ensure 
              your cybersecurity program maximizes value while minimizing risk.
            </p>
          </div>
          <div className={`${styles.services} ${styles.threeCol}`}>
            <div className={styles.service}>
              <div className={styles.serviceIcon}>
                <RiFolderUserLine />
              </div>
              <div className={styles.serviceName}>
                Fraction or Virtual Governance
              </div>
              <div className={styles.serviceDescription}>
                CISOs are expensive – we provide the human capital to supercharge your progress at a 
                fraction of the cost.
              </div>
            </div>
            <div className={styles.service}>
              <div className={styles.serviceIcon}>
                <RiBodyScanLine />
              </div>
              <div className={styles.serviceName}>
                Deep / Dark / Surface Web Analysis and Monitoring
              </div>
              <div className={styles.serviceDescription}>
                We provide the “ground truth” of what’s actually happening across your organization.
              </div>
            </div>
            <div className={styles.service}>
              <div className={styles.serviceIcon}>
                <RiBarChart2Fill />
              </div>
              <div className={styles.serviceName}>
                Regulatory or Risk-Based Assessments
              </div>
              <div className={styles.serviceDescription}>
                Our assessments help ensure you’re in compliance while also helping you actually reduce 
                risk – tailored to your specific needs.
              </div>
            </div>
          </div>
        </div>
     </Container>
    </div>
  )
}
 
export default RiskBased
