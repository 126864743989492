import React from "react"
import {
  RiMoneyDollarBoxLine,
  RiFileTextLine,
  RiMacbookLine,
  RiTeamLine,
} from "react-icons/ri"

import Container from "../container/container"
import styles from "./section.module.scss"

const IndustrySpecific = () => {
  return (
    <Container>
      <div className={styles.section}>
        <h3 className={styles.title}>Industry-Specific</h3>
        <div className={styles.divider} />
        <div className={styles.text}>
          <p>
            Cybersecurity isn’t one-size-fits-all, and it’s not gained through tools. 
            Our offerings are tailored to your environment and its unique threats and 
            constraints.
          </p>
        </div>
        <div className={`${styles.services} ${styles.fourCol}`}>
          <div className={styles.service}>
            <div className={styles.serviceIcon}>
              <RiMoneyDollarBoxLine />
            </div>
            <div className={styles.serviceName}>
              Private Equity and Regulated Risk
            </div>
            <div className={styles.serviceDescription}>
              We help you understand your risk across your portfolio.
            </div>
          </div>
          <div className={styles.service}>
            <div className={styles.serviceIcon}>
              <RiFileTextLine />
            </div>
            <div className={styles.serviceName}>
              Cyber Insurers and Litigation Services
            </div>
            <div className={styles.serviceDescription}>
              Better decision making before insuring and after a claim.
            </div>
          </div>
          <div className={styles.service}>
            <div className={styles.serviceIcon}>
              <RiMacbookLine />
            </div>
            <div className={styles.serviceName}>Higher Education</div>
            <div className={styles.serviceDescription}>
              Combat cyber threats while balancing budgets and the requirement for openness.
            </div>
          </div>
          <div className={styles.service}>
            <div className={styles.serviceIcon}>
              <RiTeamLine />
            </div>
            <div className={styles.serviceName}>
              Recruiters and Staffing Agencies
            </div>
            <div className={styles.serviceDescription}>
              Understand the human and their activities that might bring risk to your client.
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default IndustrySpecific
